.toolbar{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.color-gray{
    color: var(--dim-gray);
}

.fileContainer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.fileBtn, .fileBtn:hover{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--second-color) !important;
    color: var(--main-color) !important;
    font-size: var(--font-size-md);
    border: none !important;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    height: 40px;
}

.fileInputWordPdf{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 60px;
    height: 38px;
    cursor: pointer;
}

.fileInputHandwriting{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 80px;
    height: 38px;
    cursor: pointer;
}