.content__submissions__student{
    .filter-btn{
        left: 24% !important;
    }
}

.w-max-content{
    min-width: max-content !important;
}

.sub-table{
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #ccc;

}
.sub-table-header-item,.sub-table-row-item{
    display: flex !important;
    border-bottom: 1px solid #ccc;
}
.sub-table-title-item,.sub-table-body-item{
    flex-grow: 1 !important;
    padding: 12px 16px;
    border-right: 1px solid #ccc;
    display: flex !important;
    justify-content: center
}
.sub-table-title-item{
    font-weight: bold;
}
.expandedTable{
    color: #1C1D1E !important;
    background-color: #f7f9fc !important;
}
.expandedTable td{
    background-color: transparent !important;
}
.row-clickable{
    cursor: pointer;
}
.algin-center{
display: flex !important;
align-items: center;
justify-content: center;
}

.algin-center .tableBody__cell-formatCol {
    padding: 8px 12px !important;
}

.action__container{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}