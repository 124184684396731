
.app-layout{
    display: flex;
    gap: 20px;
    background-image: url("../../assets/images/bg-05.svg");
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.app-layout .layout .main-content{
    overflow: auto;
    height: 100vh;
    padding-bottom: 2rem;
}

.app-layout .main-content .header .ant-menu .ant-menu-item{
    padding: 0px;
}

.ant-dropdown-menu-title-content label input[type="checkbox"]{
    border-radius: 0px !important;
    accent-color: var(--main-color) !important;
    block-size: 1.2rem !important;
    inline-size: 1.2rem !important;
    cursor: pointer;
    padding: 2rem !important;
}

.ant-dropdown-menu-title-content label{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}