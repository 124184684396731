
.data__loading{
    position: relative !important;
    height: 500px !important;
    .data__loading__spin{
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

    }
}