.container {
    padding: 24px;
    max-width: 100%;
    margin-top: 20px;
    border-radius: 14px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 6.1px 0px #0000002B;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(221px, 1fr));
    gap: 16px;
    margin-top: 24px;
}

/* Header.module.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.backButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: #FFFFFF !important;
    border: 1px solid #EAECF0 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}

.filterBtn{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filterAndTitle{
    display: flex;
    gap: 8px;
    align-items: center;
}

.mainTitle {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.subTitle {
    font-size: 20px;
    color: var(--main-color);
    margin: 0;
}

.controls {
    display: flex;
    gap: 16px;
    align-items: center;
}

.filterButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: background-color 0.2s;
    height: 41px;
}

.filterButton:hover {
    background-color: #f9fafb;
}

.icon {
    width: 16px;
    height: 16px;
}

.searchContainer {
    position: relative;
}

.searchIcon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: #9ca3af;
}

.searchInput {
    padding: 8px 16px 8px 36px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    outline: none;
    height: 41px;
}

.searchInput:focus {
    border-color: #2563eb;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .controls {
        width: 100%;
        flex-direction: column;
    }

    .searchContainer {
        width: 100%;
    }

    .searchInput {
        width: 100%;
    }

    .filterButton {
        width: 100%;
        justify-content: center;
    }
}