/* inter fonts */
@font-face { font-family: "Inter"; font-style: normal; font-weight: 100; font-display: swap; src: url("assets/fonts/Inter/Inter-Thin.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 100; font-display: swap; src: url("assets/fonts/Inter/Inter-ThinItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 200; font-display: swap; src: url("assets/fonts/Inter/Inter-ExtraLight.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 200; font-display: swap; src: url("assets/fonts/Inter/Inter-ExtraLightItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 300; font-display: swap; src: url("assets/fonts/Inter/Inter-Light.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 300; font-display: swap; src: url("assets/fonts/Inter/Inter-LightItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("assets/fonts/Inter/Inter-Regular.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 400; font-display: swap; src: url("assets/fonts/Inter/Inter-Italic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("assets/fonts/Inter/Inter-Medium.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 500; font-display: swap; src: url("assets/fonts/Inter/Inter-MediumItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("assets/fonts/Inter/Inter-SemiBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 600; font-display: swap; src: url("assets/fonts/Inter/Inter-SemiBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("assets/fonts/Inter/Inter-Bold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 700; font-display: swap; src: url("assets/fonts/Inter/Inter-BoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 800; font-display: swap; src: url("assets/fonts/Inter/Inter-ExtraBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 800; font-display: swap; src: url("assets/fonts/Inter/Inter-ExtraBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 900; font-display: swap; src: url("assets/fonts/Inter/Inter-Black.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 900; font-display: swap; src: url("assets/fonts/Inter/Inter-BlackItalic.woff2") format("woff2"); }


body {
  box-sizing: border-box;
  background-color: #f8f9fd !important;
  min-width: 320px;
  min-height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  margin: 0 auto !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading{
  position: relative !important;
  height: 100vh !important;
  .loading__spin{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: var(--main-color) !important;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-message{
  color: red;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  margin-top: 3.7px;
}

:root {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  line-height: 1.5;
  font-weight: 400;
  overflow: hidden;
  background-color: #EFF2F5;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease .2S;
  --main-spacing: 16px;
  --main-color : #000095;
  --main-color-hover : #1818B1 ;
  --second-color : #FDD832;
  --second-color-hover : #EFC50E;
  --white-color : #fff;
  --black-color : #000;
  --text-color: #7D7D7D;
  --dim-gray: #4B4947;
  --shadow : 0px 0px 3.63px 0px #00000040;
  --main-radiuse : 8px;
  --btn-radius : 9.25px;
  --modal-radius : 27px;

  /*font size*/
  --font-size-xxs : 10px;
  --font-size-xs : 12px;
  --font-size-sm : 14px;
  --font-size-md : 16px;
  --font-size-lg : 18px;
  --font-size-xl : 20px;
  --font-size-xxl : 24px;
  --font-size-xxxl : 32px;
  --font-size-big-title : 32px;
  --font-size-xxxxl : 40px;
  --font-size-xxxxxl : 48px;
  --font-size-xxxxxxl : 56px;
  --font-size-xxxxxxxl : 64px;

  /*font weight*/
  --font-weight-regular : 400;
  --font-weight-medium : 500;
  --font-weight-semibold : 600;
  --font-weight-bold : 700;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #FDD832;
}
::-webkit-scrollbar-thumb {
  background: #000095;
}

*{
  font-family: "Inter", sans-serif !important;
}

.table{
  font-size: var(--font-size-md) ;
  font-weight: var(--font-weight-regular) ;
}

button, .ant-btn{
  font-size: var(--font-size-md);
}

.ant-picker .ant-picker-input >input{
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-regular) !important;
}

.ant-picker .ant-picker-input >input::placeholder{
  font-weight: 500 !important;
  color: #7D7D7D !important;
}
.ant-select-item-option-content, .ant-select-selection-item{
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-regular) !important;
}

.color-light-gray {
  color: #aaa;
}

.color-black {
  color: black;
}

.color-gray {
  color: #ccc;
}

.cursor-pointer{
  cursor: pointer;
}