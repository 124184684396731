:root {
    --main-spacing: 10px;
    --text-color: #7D7D7D;
}

.container {
    width: 100%;
    height: 100dvh;
    margin: auto;
    display: flex;
    align-items: center;
    background-image: url("../../assets/images/BG-1479a 3.svg");
    background-size: cover;
}

.createNewPassword {
    background-color: #fff;
    color: #4B4947;
    width: 390px;
    margin: 0 auto;
    border-radius: 30px;
    padding: 4.5rem 4rem;
}

.createNewPassword__title {
    font-size: 30px;
    margin-bottom: var(--main-spacing);
    text-align: center;
}

.createNewPassword__subTitle {
    font-size: 17px;
}

.createNewPassword__form {
    margin-top: var(--main-spacing);
}

.createNewPassword__form-password {
    margin-bottom: var(--main-spacing);
}

.createNewPassword__form-password input::placeholder {
    color: var(--text-color);
}

.createNewPassword__form-checkbox {
    font-size: 17px;
    color: var(--text-color);
    font-weight: 400;
}

.createNewPassword__form-checkbox a {
    color: #02027c !important;
}

.createNewPassword__form-checkbox a:hover {
    text-decoration: underline;
}

.createNewPassword__form-checkbox .ant-checkbox {
    align-self: self-start;
    margin-top: 9px;
}

.createNewPassword__form-checkbox .ant-checkbox-inner {
    border-radius: 0px !important;
}

.createNewPassword__form-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000095 !important;
    border-color: #000095 !important;
}

.createNewPassword__form-btn {
    font-weight: 600;
    background-color: #000095;
    color: #FDD832;
    margin-top: var(--main-spacing);
}

.createNewPassword__form-btn:hover {
    background-color: #02027c !important;
    color: #FDD832 !important;
}
