
.app-correction{
    display: flex;
    gap: 20px;
    padding: 51px;
}

.content-main{
    padding: 24px;
    margin: 0;
    min-height: 280px;
}
.row-header{
    margin-bottom: 1.5%;
}
.button{
    border-radius: 8px !important;
}
.button-big{
    height: 40px;
}
.button-small{
    height: 30px;
    font-size: 12px;
}
.button-secondary{
    color: #000095;
    background: #fff;
    border: 1px solid #000095
;
}
.label{
   margin-bottom: 1%;
   margin-right:  1%;
   font-weight: 700;
    display: inline-block;
}
.font-weight-700{
    font-weight: 700;
}
.font-weight-600{
    font-weight: 600;
}
.font-weight-500{
    font-weight: 500;
}
.header{
    background: #ffffff;
}
/*.layout{*/
/*    background-image: url("../../assets/images/bg-4.svg");*/
/*    background-size: cover;*/
/*    background-position: top;*/
/*    background-repeat: no-repeat;*/
/*}*/
  .header-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
/*.main-content{*/
/*  display: flex;*/
/*  gap: 16px;*/
/*  padding: 0 50px;*/
/*  margin-right: auto;*/
/*  !*padding-left: 15px;*!*/
/*    !*padding-right: 15px;*!*/
/*    width: 100%;*/
/*}*/
.sidebar-container{
    min-width: 450px;
    max-width: 450px;
    height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: scroll;
    .ant-collapse-content{
        border-top: 1px solid #f3f3f3 !important;
    }
}
.card-editor{
    margin-top: 1%;
}
.input-text{
    height: 30px;
}
.hr{
    width: 95%;
    text-align: center;
    border: 1px solid #f3f3f3;
}

.editor-conrainer {
    flex-grow: 1;
}
.editor-conrainer .ant-spin-container{
    height:calc(100vh - 300px);
}
.editor-conrainer  .ant-spin-container > div {
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    height: min-content !important;
    outline: none !important;
}
.editor-conrainer .ant-spin-container > div > div{
    height: min-content;
}
.right-content-header{
    display: flex;
    align-items: flex-end;
}
.mistakes-corrected{
    background: #1a1ea1;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border-radius: 8px;
    margin-top: 2%;
    align-items: center;
    padding: 10px 20px;
    width: max-content;
    display: flex;
    .nbr-mistackes{
        white-space: nowrap;
    }
    img{
        width: 23px;
    }
}

.app-correction .btn-correct, .app-correction .btn-correct:hover, .app-correction .btn-correct:visited, .app-correction .btn-correct:active{
    font-family: 'Work Sans', sans-serif;
    color: var(--main-color) !important;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    border: 1px solid var(--main-color);
    border-color: var(--main-color) !important;
}

.app-correction .btn-upload{
    font-family: 'Work Sans', sans-serif;
    background-color: var(--main-color) !important;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
}