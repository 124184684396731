.container {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    padding: 1.5rem;
    background: #f8f9fa;
}

.leftColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rightColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.questionCard {
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.questionHeaderYellow {
    background: #ffc107;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.questionHeaderBlue {
    background: #1a237e;
    color: white;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.questionHeaderPurple {
    background: #7b1fa2;
    color: white;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.questionContent {
    padding: 1rem;
}

.mapContainer {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
}

.mapMarker {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background: #ffc107;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.optionsGroup {
    display: flex;
    gap: 1rem;
}

.option {
    flex: 1;
    padding: 0.5rem;
    text-align: center;
    background: #f8f9fa;
    border-radius: 0.25rem;
    cursor: pointer;
}

.optionSelected {
    background: #ffc107;
}

.textInput {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
}

.answerCard {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.answerHeader {
    background: #ffe082;
    margin: -1rem;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.answerOptions {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.answerButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: pointer;
}

.answerButtonSelected {
    background: #ffe082;
    border-color: #ffc107;
}

.justification {
    color: #1976d2;
    font-size: 0.875rem;
    line-height: 1.5;
}

.hint {
    color: #9e9e9e;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.exportButton {
    background: #1a237e;
    color: white;
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-align: center;
    margin-top: auto;
    cursor: pointer;
}