@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


.newAssignment-modal{
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  top: 12px;
    width: 40% !important;

}
.newAssignment-modal .ant-modal-content{
    overflow-y: auto;
    max-height: 88vh;
}
@media (min-height : 900px) {
    .newAssignment-modal{
        top: 5%;
    }
}

.newAssignment-modal .ant-modal-content{
    padding: 16px 24px !important;
}

.newAssignment-modal .ant-modal-content .ant-modal-body{
    display: flex;
    flex-direction: column;
}

.newAssignment-modal .ant-modal-title{
    font-weight: 600 !important;
    font-size: 19.47px !important;
    line-height: 22.72px !important;
    margin-bottom: 22.15px !important;
    color: #0C111D;
}

.assignmentFields{
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

@media (min-width : 1280px) {
    .assignmentFields{
        margin-bottom: 25px;
    }
    .last-child{
        margin-bottom: 7px !important;
    }
}
@media (min-width : 1680px) {
    .last-child{
        margin-bottom: 25px !important;
    }
}

.assignmentFields-title{
    font-size: 13px;
    font-weight: 600;
    line-height: 15.33px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
    margin-bottom: 3.37px !important;
}
.assignmentFields-title.field__feacher {
    margin-bottom: 10px !important;
}

.assignmentFields-desc{
    color: #979797;
    font-size: 11px;
    font-weight: 600;
    line-height: 14.3px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
    margin-bottom: 3.37px !important;
    margin-top: 2px;
}
.select-rubric{
    width: 90% !important;
}
.assignmentFields .ant-select:not(.select-rubric) {
    width: 100% !important;
}


.assignmentFields-rubricBtn{
    width: fit-content !important;
    background-color: #FFCF00;
    color: #fff;
    padding: 15px 18px;
    gap: 3px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
}
.assignmentFields-rubricBtn:hover{
    background-color: var(--main-color) !important;
    color: var(--second-color) !important;
    /*background-color: #b4a564 !important;*/
    /*color: #fff !important;*/
}


.assignmentFields-addBtn{
    background-color: transparent;
    color: var(--main-color) ;
    font-family: inherit;
    width: fit-content ;
    border: none ;
    padding: 0 ;
    margin-top: 3.7px;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    cursor: pointer;
}

.assignmentFields-addBtn:hover{
    color: var(--main-color-hover) !important;
}

.createAssignment-btn{
    /*padding: 10px 71px !important;*/
    background-color: var(--main-color) !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 14.23px;
    align-self: center;
    width: 200px;
    padding: 2% !important;
    border-radius: 8px;
    &:hover{
        background-color: var(--main-color-hover) !important;
        cursor: pointer;
    }
}

.createAssignment-btn:hover{
    background-color: var(--main-color-hover) !important;
}
.newAssignment-form .error{
    color: red;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    margin-top: 3.7px;
}

.features__field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .ant-switch.ant-switch-checked{
        background: var(--main-color) !important;
    }
}

.practice__mode{
    margin-bottom: 0 !important;
}

.practice__mode .assignmentFields-title__practice_mode{
    font-size: 13px;
    font-weight: 600;
    line-height: 15.33px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
    margin-bottom: 10px !important;
}