
.criteriaScore{
    width: 245px;
    padding: 8px;
    border-radius: var(--raduis);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}

.criteriaScore .levelsBox{
    display: flex;
    gap: 4px;
    margin-bottom: 8px;
}

.criteriaScore .levelsBox .criteriaLevel,
.criteriaScore .levelsBox .ant-input-number{
    color: #000 !important;
    flex: 1;
    text-align: center;
}

.criteriaScore .levelsBox .ant-input-number .ant-input-number-input{
    font-weight: 700;
    text-align: center;
}

.criteriaScore .levelsBox .ant-input-number .ant-input-number-input::placeholder{
    font-weight: normal;
}

.criteriaScore .description{
    height: 135px !important;
    resize: none;
}

.criteriaScore .deleteCriteriaScore{
    background-color: transparent;
    align-items: center;
    display: flex;
    gap: 4px;
    border: none;
    outline: none;
    align-self: end;
    margin-top: 8px;
    color: #FFCF00;
    cursor: pointer;
}
.levelsBoxTitle{
    margin-bottom: 3%;
    font-weight: 700;
}
.e-msg{
    margin-top: 5px;
}