.table-container {
    position: relative;
}

/*
    ###- Button filter and the search bar -###
*/

.table-filter-sectoin .filter-btn{
    padding: 4px 10px !important;
    box-shadow: 0px 0px 3.63px 0px #00000040;
    position: absolute;
    top: -3.2rem;
    left: 9.8rem;
    font-size: var(--font-size-md);
    line-height: 18.2px;
    font-weight: 600;
    height: 40px;
}

.table-filter-sectoin .filter-btn span{
    color: #000000 !important;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
}

.table-filter-sectoin .filter-btn:hover{
    color: #000095 !important;
}


@media (min-width : 1680px) {
    .table-filter-sectoin .search-bar {
        width: 17rem;
    }
}

@media (min-width : 1920px) {
    .table-filter-sectoin .search-bar {
        width: 20rem;
    }
}

/*
    ###- Table -###
*/

.table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 0px 3.63px 0px #00000040;
}

.table thead {
    border-bottom: 1px solid #c9c1c130;
}

.table thead th {
    background-color: #fff;
    color: #000;
    padding: 16px 3px 16px 10px;
    font-weight: 600;
    font-size: var(--font-size-md);
    text-align: left;
    width: auto;
}

@media (min-width : 1280px) {
    .table thead th {
        padding-top: 18px !important;
        padding-bottom: 18px !important;
    }
}

.table thead th .tableHead__cell {
    display: flex;
    gap: 4px;
}

.table thead th .tableHead__cell .sort_column span{
    font-weight: 600 !important;
    color: #000 !important;
}

.table thead th .tableHead__cell .sort_column .sort__icons{
    gap: 2px !important;
}

.table thead th .tableHead__cell .sort_column .sort__icons span{
    height: 10px !important;
}

.table thead th .tableHead__cell .sort_column .sort__icons svg{
    font-size: 14px !important;
    color: #3B4D6F !important;
}

.table thead th img {
    transform: rotate(90deg);
}

.table thead th[data-index='id'] {
    width: 4.25% !important;
}

.table thead th[data-index='Assignment_Title'] {
    width: 17% !important;
}

.table th[data-index='Description'] {
    width: 18.75% !important;
}

.table th[data-index='Action'] {
    width: 6.25% !important;
}

.table tbody tr:nth-child(even){
    background-color: #fff;
    
}

.table tbody tr:nth-child(odd){
    background-color: #F7F9FC;
}

.table tbody tr {
    color: #475467;
    border-bottom: 1px solid #EAECF0
}

.table tbody tr:hover {
    background-color: #F7F9FC;
    color: #1C1D1E;
}

.table tbody td {
    /* margin: 6px 2px; */
    padding-block: 6px;
    padding-inline: 10px;
    text-align: left;
    font-size: var(--font-size-md);
    height: 71px !important;
    max-height: 41px !important;
    max-width: 22rem;
}

.table tbody td[data-index='id'] {
    text-align: left;
    color: #000;
}

.table td[data-index='Description'] {
    display: flex;
    align-items: center;
}

.table td div:not(.table td .Format) {
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    display: -webkit-box;
    width: 100%;
}

.table td[data-index='Rubric_Title'] {
    width: 300px;
    max-width: 28rem !important;
}

.table td .Rubric_Title {
    -webkit-line-clamp: 1 !important;
    width: 80% !important;
}

.table td[data-index='title'] {
    color: #101828;
}


@media (max-width: 1024px) {

    .table th[data-index='Description'],
    .table td[data-index='Description'],
    .table th[data-index='Assignment_Title'],
    .table td[data-index='Assignment_Title'] {
        max-width: 10rem;
    }
}

@media (min-width : 1366px) {
    .table td[data-index="Rubric_Title"]{
        width: 28rem !important;
    }
}

@media (min-width : 1680px) {

    .table th[data-index="Description"],
    .table td[data-index="Description"] {
        width: 28rem;
        padding-right: 4rem !important;
    }

    .table th:last-child,
    .table td:last-child {
        width: 110px;
        text-align: left;
    }
}

.tableBody__cell-openBtn {
    background-color: #000095;
    color: #fff;
    margin-right: 5px;
    padding: 5px 10px !important;
    display: flex;
    align-items: end;
    border-radius: 10px !important;
    height: 100%;
}

.tableBody__cell-openBtn:hover {
    background-color: #02027c !important;
    color: #FDD832 !important;
}

.tableBody__cell-formatCol {
    padding: 3px 8px;
    border-radius: 4px;
    background-color: #000095;
    color: #fff;
    box-decoration-break: clone;
    -webkit-box-decoration-break : clone;
}

.tableBody__cell-edit{
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
}

.no-results,
.no-data{
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 30rem;
    top: 42px;
    display: flex;
    justify-content: center;
    border-radius: 0 0 14px 14px;
    align-items: center;
    box-shadow: 0px 2.8px 3.63px 0px #00000040;
}

.no-data{
    color: #b09d9d;
    font-size: 20px;
    flex-direction: column;
    gap: 8px;
}

/*
    ###- pagination -##
*/

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.pagination.end {
    justify-content: end;
}

.pagination .pagination__perPage {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size-md);
}

.pagination .pagination__perPage .ant-select .ant-select-selector {
    border-color: rgba(239, 240, 242, 1) !important;
    box-shadow: none !important;
}

.pagination .ant-pagination-total-text {
    font-size: var(--font-size-md);
    color: #919AAB;
}

.pagination .ant-pagination-item {
    border: 0.91px solid rgba(239, 240, 242, 1);
    background-color: #fff !important;
}

.pagination .ant-pagination-item-link {
    background-color: #fff !important;
    border: 0.91px solid rgba(239, 240, 242, 1);
    display: contents;
}

.pagination .ant-pagination-item-active {
    font-size: var(--font-size-md);
    background-color: #000095 !important;
}

.pagination .ant-pagination-item-active a {
    color: #fff !important;
}

.submissions-counter{
    font-size: var(--font-size-md);
    color: #475467;
    display: flex !important;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    .doc-icon{
        display: flex !important;
        height: 15px;
        width: 15px;

    }

    .ant-badge-count{
        color: #000000;
    }
}