.custom__modal .ant-modal-content{
    background-color: var(--white-color) ;
    border-radius: var(--modal-radius);
}

.custom__modal .ant-modal-content .ant-modal-header .ant-modal-title h3{
    color: var(--dim-gray);
    font-size: var(--font-size-big-title);
    font-weight: var(--font-weight-bold);
    line-height: 54px;
    text-align: center;
}
.custom__modal .ant-modal-body span.sub__title{
    font-size: var(--font-size-xl);
    line-height: var(--font-size-xxl);
    font-weight: var(--font-weight-regular);
    color: var(--dim-gray);
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
}