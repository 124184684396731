.custom__notification{
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    z-index: 9999999;
}
.notification {

    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    color: white;
    animation: fadeIn 0.5s, fadeOut 0.5s 4.5s forwards;
}

.success {
    background-color: #4CAF50; /* Green */
}

.error {
    background-color: #f44336; /* Red */
}

.info {
    background-color: #2196F3; /* Blue */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
