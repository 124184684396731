.underline{
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
}
.underline-red{
    text-decoration-color: #ff4d4f;
}
.underline-green{
    text-decoration-color: #52c41a;
}
.underline-yellow{
    text-decoration-color: #FFFF00FF;
}
.underline-blue{
    text-decoration-color: #1454b4;
}
.underline-success{
    text-decoration-color: #e7e7e7;
}
.text-add-correction{
    color: #1a1ea1;
    font-weight: bold;
}
.open-tooltip .ant-tooltip-inner .anticon{
    display: none;
}
.open-tooltip .ant-tooltip-inner{
    padding: 12px 15px;
}
.tooltip-text{
}
.slate-text{
    overflow-y: auto;
}