
.rubric__form-criteria{
    margin-top: 17px;
}

.criteriaTitle{
    display: flex;
    align-items: center;
    gap: 20px;
}

.criteriaTitle-field{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 245px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px;
    border-radius: var(--raduis);
}

.criteriaTitle-field input{
    height: 40px;
}

.scoreDefinitions {
    margin-top: 22.87px;
    display: flex;
    gap: 11px;
    align-items: center;
}

.scrolling-wrapper{
    display: flex;
    align-items: center;
    gap: 11px;
}

.scrolling-wrapper::-webkit-scrollbar {
    display: none;
}

.delete, .add{
    border-radius: 50%;
    padding: 10px 9px;
    background-color: #FDD832;
    border: none;
}

.delete:hover , .add:hover{
    background-color: #efc50e !important;
    border: none !important;
}