@import "../auth.css";

.verification{
    padding: 103.51px 88.5px;
}

.verification__subTitle{
    font-size: 16.81px;
    line-height: 24.41px;
    font-weight: 500;
}