.cardError {
  color: #000;
  border-radius: 10px;
  h5 {
    font-size: 14px;
    font-weight: 400;
    color:#979797;
    margin: 10px 0;
  }
  p{
    font-size: 14px;
    font-weight: 400;
    color:#000000;
  }
  .button{
    padding: 10px;
    height: 31px !important;
    border-radius: 4px !important;
  }
  .button-right{
    margin-left: auto;
  }
  .button-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .button-card{
    border: none;
    color: #667085;
  }
}