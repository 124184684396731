
.analytics-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8.32px;
}

.analytics-percentage{
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.analytics-label{
    font-family: Work Sans;
    font-size: 11px;
    font-weight: 500;
    line-height: 15.31px;
    text-align: center;
    color: #0C111D;
    padding-inline: 5px;
    text-transform: capitalize;
}

@media (min-width: 1440px) {
    .analytics-label{
        padding-inline: 6px !important;
    }
}
@media (min-width: 1680px) {
    .analytics-label{
        padding-inline: 0px !important;
    }
}