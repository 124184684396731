@import "../auth/auth.css";

.login{
    padding: 34.88px 88.5px;
}

.login__title{
    margin-bottom: calc(var(--main-spacing) + 2px);
}

.login__subTitle {
    font-size: 17px;
    margin-bottom: calc(var(--main-spacing) + 2px);
}

.login__form{
    margin-bottom: calc(var(--main-spacing) + 2px);
    display: flex;
    flex-direction: column;
}
.login_form-emailField{
    margin-bottom: var(--main-spacing);
}

.login__form-email::placeholder {
    color: var(--text-color);
}

.login__form-forgot {
    margin-bottom: 1.5px;
    max-width: fit-content;
    align-self: end;
}

.login__form-forgot:hover {
    text-decoration: underline;
}

.login__form-passwordField{
    margin-bottom: calc(var(--main-spacing) + 2px);
}

.login__form-password input::placeholder {
    color: var(--text-color);
}

.login__form-email,
.login__form-password{
    border: none;
    box-shadow: 0px 0px 9.25px 0px rgba(0, 0, 0, 0.05);
}

.createAccount{
    align-self: center;
    color: var(--text-color);
    font-size: 16.18px;
    line-height: 18.98px;
}

.createAccount a{
    color: var(--main-color);
    font-weight: 600;
}
.createAccount a:hover{
    text-decoration: underline;
}

.login__otherOptions-register {
    text-align: center;
}