@import "../auth.css";

.choseRole__subTitle {
    font-size: 20.81px;
}

.choseRole__subTitle a {
    color: var(--main-color) !important;
}

.choseRole__subTitle a:hover {
    text-decoration: underline;
}

.choseRole__form{
    display: flex;
    flex-direction: column;
}

.choseRole__role{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-bottom: 45px;
}

.choseRole__role .choseRole__role-teacher input[type=radio],
.choseRole__role .choseRole__role-student input[type=radio]{
    display: none;
}

.choseRole__role .choseRole__role-teacher input[type=radio]:checked + label>img,
.choseRole__role .choseRole__role-student input[type=radio]:checked + label>img{
    box-shadow: 0px 0px 4px 0px rgba(255, 207, 0, 0.3);
}

.choseRole__role .choseRole__role-teacher input[type=radio]:checked + label,
.choseRole__role .choseRole__role-student input[type=radio]:checked + label{
    color: var(--second-color);
}

.choseRole__role .choseRole__role-teacher label,
.choseRole__role .choseRole__role-student label{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    color: var(--main-color);
    font-weight: 400;
    font-size: 20.81px;
    line-height: 24.41px;
    cursor: pointer;
    width: 194px !important;
}

.choseRole__role .choseRole__role-teacher label img,
.choseRole__role .choseRole__role-student label img{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 150px;
    width: 145px;
    padding: 25px 25px;
    border-radius: 8px;
}

.choseRole .choseRole__continueBtn{
    display: flex;
    align-self: center;
    width: 163px;
}
.sign_up__btn__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}