main.login__page, main.register__page {
    background-image: url(../../assets/images/BG-1479a\ 3.svg);
    background-position: -9px -79px;
    background-repeat: no-repeat;
    background-size: cover;
}

.container {
    width: 700px;
    height: 100dvh;
    margin: auto;
    display: flex;
    align-items: center;
}

.__box{
    background-color: #fff;
    width: 390px;
    margin: 0 auto;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    padding: 31.75px 88.5px;
}

.__remediaLogo {
    position: absolute;
    left: 31px;
    top: 18px;
    width: 250px;
}

@media (min-width:1280px) {
    .__remediaLogo {
        left: 41px;
        top: 28px;
    }
}

.__title {
    color: #4B4947;
    font-size: 36px;
    margin-bottom: var(--main-spacing);
    text-align: center;
    font-weight: 700;
}

.__mixSubTitle{
    margin-bottom: var(--main-spacing);
    color: #4B4947;
    font-weight: 400;
    text-align: center;
    display: block;
}

.__authBtn{
    background-color: var(--main-color) !important;
    color: var(--second-color) !important;
    padding: 9.25px 18.49px !important;
    border-radius: 9.25px !important;
    line-height: 21.69px;
    font-size: 18.49px;
    font-weight: 600;
    margin-top: 1rem;
    text-align: center;
    cursor: pointer;
}

.__errorField{
    display: flex;
    /* padding: .6rem 1rem !important;
    font-size: medium !important;
    background-color: #fff3f5;
    gap: 8px; */
    align-items: center;
    margin-bottom: 18.75px;
    /* border-radius: 0.75rem;
    color: black ; */
}

.__errorMessage{
    padding: 5px 0;
    color: red;
    font-size: x-small;
}

.__authBtn:hover{
    background-color: var(--main-color-hover) !important;
    color: var(--second-color) !important;
}

.__backToSign-in{
    color: var(--main-color);
    margin-top: var(--main-spacing);
    justify-content: center;
    width: fit-content;
    align-self: center;
    font-weight: 600;
    display: flex;
    gap: 6px;
}

.__backToSign-in:hover{
    color: var(--main-color-hover);
}

.__backToSign-in .anticon{
    transition: transform 0.3s ease;
}

.__backToSign-in:hover .anticon{
    transform: translateX(-5px);
}