aside{
    background-color: transparent !important;
}
.list{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 9px 0  8px;
    margin-top: 1rem;
}

.list .active .list-btn{
    background-color: var(--main-color);
    color: #fff;
}

.list-btn{
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
    display: flex;
    align-items: center;
    gap: 11px;
    width: 100%;
    padding: 10px 11px;
    border-radius: 8px;
    font-size: var(--font-size-md);
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    transition: all ease-out .2s;
}

.list-btn.collapsed{
    width: 40%;
    justify-content: center;
}

.list-btn.collapsed .icon{
    font-size: 18px !important;
}

.list-btn:hover{
    background-color: #F7F9FC;
}

.classroom-name{
    font-size: var(--font-size-xl);
    line-height: 20px;
    color: var(--main-color);
    background: #FDD832;
    padding: 11px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
}

.classroom-name.collapsed{
    width: 40%;
    justify-content: center;
}

.sidebar.sidebarCollapsed + main .container-help-assistant {
    min-width: calc(100% - 120px); 
  }
  .sidebar.sidebarCollapsed + main .content-help-assistant{
    min-width: calc(100% - 120px);
  }
  .sidebar.sidebarCollapsed + main  .mask-help-assistant{
    width: calc(100% - 80px);
  }