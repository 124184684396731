.card {
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    background: white;
}

.content {
    display: flex;
    gap: 12px;
    padding: 16px;
}

.avatar {
    width: 48px;
    height: 48px;
    background-color: var(--second-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.details {
    flex: 1;
}

.infosBlock{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.studentInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.name {
    font-size: 16px;
    font-weight: 500;
}

.scoreBadge {
    background-color: var(--main-color);
    color: var(--second-color);
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
}

.dateLabel {
    color: #6b7280;
    font-size: 14px;
    margin-top: 4px;
}

.date {
    color: var(--main-color);
    font-size: 14px;
    font-weight: 600;
}

.correction {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    border-top: 1px solid #EAECF0;;
    padding: 16px;
}


.correctionIcon {
    width: 16px;
    height: 16px;
    color: var(--second-color);
}

.correctionTextDone {
    color: var(--second-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.correctionTextPending {
    color: var(--main-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: end;
    cursor: pointer;
}

.correctionBlocPending{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 480px) {
    .grid {
        grid-template-columns: 1fr;
    }
}