
.classStudentContainer{
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding-left: 23px;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    z-index: 0 !important;
    transition: all ease .1s;
    box-shadow: 0px 0px 10.4px 0px #0000001A;

    h1{
        font-family: Inter;
        font-size: 24px;
        margin-bottom: 14px;
    }

    .classContainer__content > div:not(:last-child){
        margin-bottom: 14px;
    }

    .classContainer__content > div > span{
        font-size: var(--font-size-sm);
        line-height: 18.2px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .classContainer__value{
        color: var(--main-color);
    }

    .classVectorBg{
        position: absolute;
        top: 0;
        right: 0;
    }

    .classLessonIcon{
        position: absolute;
        top: 18px;
        right: 24px;
    }

    .classContainer__content{
        z-index: 1000 !important;
        position: relative !important;
        color: #000 !important;
        &:hover{
            cursor: pointer;
        }
    }
  &:hover{
    cursor: pointer;
  }
}

.classStudentContainer:hover{
    transition: all ease .1s;
    color: #fff;
}
.classContainer__images{
    &:hover{
       cursor: pointer;
    }
}