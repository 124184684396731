/*
    ###- search bar position
*/
.rubricContent .table-filter-sectoin .search-bar {
    color: #5e5d5d;
    width: 15rem;
    position: absolute;
    right: 9.3rem;
    top: -3.2rem;
}

/*
    ###- rubric style 
*/

@media (min-width : 1366px) {
    .main {
        background-position: 110px -85px;
    }
}

@media (min-width : 1440px) {
    .main {
        background-position: 125px -85px;
    }
}

@media (min-width : 1680px) {
    .main {
        background-position: 80px -100px;
    }
}

@media (min-width : 1920px) {
    .main {
        background-position: 80px -130px;
    }
}

.rubricContent {
    padding-right: 1.5rem;
    padding-bottom: 10px;
    flex: 1;
}

.rubricContent__header {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}

.rubricContent__header-new {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rubricContent__header-title span{
    font-size: 20.98px;
}

.rubricContent__header-title span,
.rubricContent__header-new span {
  font-weight: 600;
}

.rubricContent__header-new button {
  background-color: #000095;
  color: #FDD832;
  padding: 8.14px 20px !important;
}

.rubricContent__header-new button:hover {
  background-color: #02027c !important;
  color: #FDD832 !important;
}
.tableBody__cell-delete{
    margin-left: 10px;
    &:hover{
        cursor: pointer;
    }
}
td[data-index="Edit"] {
    width: 4%;
}

.rubric__tabs{
    box-shadow: 0px 0px 2px 0px #0000000F;
    border-radius: 8px;
    background-color: white;
    max-width: 300px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    label{
        font-size: var(--font-size-md);
    }
    label.ant-radio-button-wrapper:hover, label.ant-radio-button-wrapper:focus {
        color: var(--main-color);
    }
    label.ant-radio-button-wrapper-checked, label.ant-radio-button-wrapper-checked:hover, label.ant-radio-button-wrapper-checked:focus {
        color: var(--main-color) !important;
        border-color: var(--main-color) !important;
    }

}