

/* Main Content Styles */

.content__submissions {
  flex-grow: 1;
  padding-right: 1.5rem;
}
.content__submissions.submissions{
  .filter-btn {
    left: 24% !important;
  }
}

.content__submissions .submission__top-page {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

.main-content .submissions__filter,
.main-content .submissions-new {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-content .submissions__filter span,
.main-content .submissions-new span {
  font-weight: 600;
}

.main-content .submissions__filter button {
  padding: 4px 10px !important;
  box-shadow: 0px 0px 3.63px 0px #00000040;
}

.main-content .submissions__filter button:hover {
  color: #000095 !important;
}

.main-content .submissions-new input {
  width: 15rem;
  color: #5e5d5d;
  height: 30px;
}

.main-content .submissions-new button {
  background-color: #000095;
  color: #FDD832;
}

.main-content .submissions-new button:hover {
  background-color: #02027c !important;
  color: #FDD832 !important;
}
.tableBody__text {
  font-size: 12px;
  line-height: 15px;
  color: #919AAB;
  &:hover {
    color: #000095;
  }
}

.main-content .content__submissions .submission__top-page .submissions__filter span {
  font-size: 21px;
  line-height: 24px;

}

.main-content .content__submissions .submission__top-page .submissions-new button{
  font-size: 13px;
  line-height: 20px;
  text-align: center;
}
.content__submissions .tableBody__cell .tableBody__cell-openBtn{
  max-height: 30px;
  padding: 7px 30px !important;
  display: flex;
    justify-content: center;
    align-items: center;
  border-radius: 7px !important;
}

.content__submissions .tableBody__cell.type {
  overflow: initial !important;
}

.content__submissions .tableBody__cell.type .tableBody__cell-formatCol {
  padding: 3px 7px !important;
  border-radius: 7px !important;
}

.content__submissions {
  .tableBody__cell.action{
    .anticon {
      height: 22px;
      font-size: 22px !important;
    }
  }
}